:root {
  --blue: #5181d9;
  --green: #30aac5;
}

.doctor-review-card {
  box-shadow: 5px 5px 5px 5px rgba(229, 233, 246, 0.6);
  border-radius: 20px;
  padding: 16px;
  font-size: 0.9em;
}

.doctor-review {
  height: 70%;
  overflow-y: scroll;
}

.doctor-review-card-mob {
  text-align: left;
  height: 400px;
}

.doctor-review-img {
  border-radius: 20px;
}

.doctor-review-img-mob {
  border-radius: 100%;
  height: 50px;
  width: 50px;
}

.doctor-name {
  font-weight: 600;
  font-size: 1.3em;
}

.doctor-country {
  font-size: 1.2em;
  color: var(--green);
  font-weight: 600;
}

.doctor-review-card-info {
  height: 80%;
  text-align: left;
}

.doctor-name {
  font-weight: 600;
  font-size: 1.3em;
}

.review-arrow img:hover {
  cursor: pointer;
}

.carousal-left-arrow {
  transform: rotate(90deg);
}

.carousal-right-arrow {
  transform: rotate(-90deg);
}
.doctor-country {
  font-size: 1.2em;
  color: var(--green);
  font-weight: 600;
}

.carousel-dot {
  border-radius: 25px;
  width: 10px;
  height: 10px;
  background-color: #a3a3a3;
  margin-right: 8px;
}

.selected {
  background-color: #222222 !important;
}

.shadow-interaction:hover {
  box-shadow: none !important;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  opacity: 0.75;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
