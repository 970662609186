.whatsapp-icon {
  position: fixed;
  top: 40px;
  bottom: 50px;
  right: 20px;
  z-index: 9999;
  background-color: #25d366;
  color: #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
}

.whatsapp-icon a {
  text-decoration: none;
  color: #fff;
}

.whatsapp-icon svg {
  width: 35px;
  height: 35px;
}

@media screen and (max-width: 1000px) {
  .whatsapp-icon {
    bottom: 20px;
    top: unset;
  }
}
