:root {
  --blue: #5181d9;
  --white: #ffffff;
  --hover-blue: #2d62d5;
}

.link:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px 25px;
  margin: 0px;
  border-radius: 40px;
  color: var(--white);
  border: none;
  background-color: var(--blue);
  text-decoration: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.2), 0px 4px 10px rgba(0, 0, 0, 0.15);
}

.sub-header {
  color: white;
}

.sub-header-item {
  color: white;
  font-weight: 700;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: white;
  }
}

.login-doctor-link {
  font-weight: 700;
  color: black;
  margin: 0px 25px;
}

.login-patient-link {
  color: black;
  font-weight: 600;
}

.divider {
  margin: 0;
  height: 20px;
  width: 1px;
  background-color: #5181d9;
}

.heading {
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  font-size: 1.8em;
}

.sub-heading {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;

  font-size: 1.2em;
}

.join-patient-button {
  width: 132px;
  height: 51px;
  border-radius: 40px;
  color: var(--white);
  border: none;
  background-color: var(--blue);
}

.join-patient-button:hover {
  background-color: var(--hover-blue);
}

.first-section {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.first-section-image {
  width: 55%;
  object-fit: contain;
}

.why-section-subheading {
  font-weight: 500;
  font-size: 1.4rem;
  max-width: 100%;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  font-display: swap;
}

.first-section-text {
  font-weight: 700;
  font-size: 3rem;
  max-width: 100%;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-display: swap;
}

.first-section-subheading {
  font-weight: 500;
  font-size: 1.4rem;
  max-width: 100%;
  text-align: left;
  font-family: 'Poppins', sans-serif;
  font-display: swap;
}

.top-banner {
  width: 100%;
  background-color: rgb(65 145 23);
  color: white;
  margin-top: 62px;
  border-radius: 20px;
  padding: 12px;
}

.banner-text {
  margin: 0;
  font-size: 20px;
}

.media-subHeading {
  font-weight: 900;
  font-size: 26px;
  color: inherit;
  &:hover {
    cursor: pointer;
  }
}

@keyframes scrollText {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.first-section-content {
  font-weight: 700;
  font-size: 3.2rem;
  width: 36%;
  text-align: left;
}

.second-section {
  flex-direction: row;
}

.heading-normal {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-size: 2.3em;
}

.heading {
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  font-size: 1.8em;
}

.sub-heading {
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
  font-size: 1.2em;
}

.second-section-text-bold {
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
}

.second-section-asset {
  width: 50%;
}

.second-section-content {
  width: 45%;
}

.section-3-heading {
  font-size: 1.1em;
  font-weight: 600;
  text-align: left;
}
.section-3-text {
  font-size: 1.1em;
  font-weight: 400;
  text-align: left;
}

.patient-review-card {
  box-shadow: 20px 20px 20px 5px rgba(229, 233, 246, 0.79);
  text-align: left;
  height: 310px;
  background-color: var(--white);
  /* padding: 32px; */
}

.patient-review-text {
  font-size: 0.95rem;
  font-weight: 400;
}

.patient-name {
  font-weight: 600;
  font-size: 1.2em;
  color: var(--blue);
}

.patient-country {
  font-weight: 400;
  font-size: 1.2em;
}

.news-article-card {
  height: 392px;
  box-shadow: 20px 20px 20px 5px rgba(229, 233, 246, 0.79);
  text-align: left;
  background-color: var(--white);
  object-fit: cover;
  cursor: pointer;
}

.article-card-link {
  font-weight: 600;
  font-size: 1em;
  color: var(--blue);
}

.client-img-1 {
  margin-right: 16px;
  cursor: pointer;
}

.how-it-works-video {
  max-width: 600px;
  min-width: 560px;
  min-height: 300px;
  height: 100%;
  margin: 20px;
  border: 2px gray solid;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.6);
}

.how-it-works-container {
  display: flex;
  justify-content: space-evenly;
}

.footer-container {
  padding: 28px;
}
.footer-link {
  color: var(--white);
}

.terms-link {
  color: black;
  font-weight: 600;
  font-size: 1.1em;
}

.m-2 {
  margin-right: 16px;
}

.location-text {
  margin-left: 6px;
  color: white;
  &:hover {
    color: inherit;
  }
}

.footer-link:hover {
  color: var(--white);
  text-decoration: none;
}

.footer-button {
  width: 160px;
  height: 50px;
  background-color: var(--blue);
  border: none;
  border-radius: 40px;
  color: var(--white);
  margin-right: 20px;
}

.footer-button:hover {
  background-color: var(--hover-blue) !important;
}

.social-media-button {
  border-radius: 100%;
  border: none;
  border: none;
  background: none;
  background-color: var(--white);
  box-shadow: 20px 20px 20px 5px rgba(229, 233, 246, 0.79);
  cursor: pointer;
}

.overlay {
  transform: translateY(0px) translateX(0px);
  transition: transform 400ms ease 0s;
  /* box-shadow: 20px 20px 20px rgba(229, 233, 246, 0.79); */
  box-shadow: 0 20px 40px -40px #4b5a8b;
  background-color: var(--white);
}

.how-it-works-image {
  width: 70%;
}

.copyright {
  color: var(--white);
  flex-direction: row;
  justify-content: space-between;
  width: 83%;
}

.copyright-container {
  padding: 4px 0px;
}

.contact-us {
  flex-direction: row;
  justify-content: space-between;
}

.footer {
  flex-direction: row;
  justify-content: space-between;
}

.shadow-interaction:hover {
  box-shadow: none !important;
}
.article-card-heading {
  font-size: 1em;
}
.article-card-link {
  font-size: 1.2em;
}
.read-more-icon {
  height: 20px;
  width: 20px;
  margin-top: 4px;
  margin-left: 12px;
}

.contact-us-links {
  text-decoration: none;
  color: black;
  font-weight: bold;
  &:hover {
    text-decoration: none;
  }
}

@media only screen and (max-width: 1280px) {
  .login-doctor-link {
    font-weight: 700;
    color: black;
    margin: 0px 25px;
  }

  .link:hover {
    cursor: pointer;
    color: #5181d9;
    background-color: white;
    text-decoration: underline;
  }

  .how-it-works-container {
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  .how-it-works-video {
    width: 100%;
    min-width: 100px;
  }
}

@media only screen and (max-width: 1024px) {
  .first-section {
    flex-direction: column;
  }
  .first-section-image {
    width: 90%;
    margin-top: 16px;
  }
  .first-section-content {
    font-weight: 700;
    font-size: 2rem;
    width: 100%;
    text-align: center;
  }

  .first-section-text {
    text-align: center;
    max-width: 100%;
    font-display: swap;
    font-size: clamp(2rem, 2.5vw + 1rem, 2.5rem);
  }

  .first-section-subheading {
    font-weight: 500;
    font-size: 1.2rem;
    max-width: 100%;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-display: swap;
  }
  .second-section {
    flex-direction: column-reverse;
  }

  .second-section-asset {
    margin-top: 32px;
    width: 100%;
  }

  .second-section-content {
    width: 90%;
    margin-top: 32px;
  }
  .patient-review-text {
    font-size: 0.9rem;
  }

  .article-card-heading {
    font-size: 0.75em;
  }
  .article-card-link {
    font-size: 0.8em;
  }
  .read-more-icon {
    height: 12px;
    width: 12px;
  }
}

@media only screen and (max-width: 800px) {
  .banner-text {
    font-size: 14px;
  }
  .footer-button {
    margin-bottom: 20px;
    margin-right: 0px;
  }
  .copyright-container {
    padding: 12px 0px;
  }

  .how-it-works-image {
    width: 85%;
  }

  .footer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact-us {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 55%;
  }

  .copyright {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-size: 0.9rem;
  }
}

@media (max-width: 450px) {
  .how-it-works-video {
    width: 90%;
    min-width: 100px;
  }
}

.specialities-card {
  box-shadow: 10px 7px 50px rgba(229, 233, 246, 0.74);
}

.button-disabled {
  cursor: not-allowed;
  pointer-events: none;
  background-color: #b1b5c2 !important;
  text-transform: uppercase;
}

.footer-link {
  cursor: pointer;
  margin-bottom: 8px;
  color: #000;
  text-decoration: none;
  &:hover {
    color: inherit;
    font-weight: bold;
    text-decoration: underline;
  }
}

.social-media-button {
  width: 32px;
  height: 32px;
  cursor: pointer;
  margin-right: 8px;
}

.footer-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #5181d9;
  margin-bottom: 0.5rem;
}
