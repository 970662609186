:root {
  --app-background: #f9fafb;
  --font: geomanistregular;
  /* --text-color: #273238;
  --background-cl: #232735;*/
  --text-color: #8b90a0;
  --background-cl: #232735;
  --btn-cl: #458ff6;
}

@font-face {
  font-family: 'geomanistregular';
  src: local('geomanistregular'), url('./fonts/geomanist-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
html,
body {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
