html,
body {
  font-family: 'geomanist';
  background-color: var(--app-background);
  /* background-color: #f7f7f7;*/
  scroll-behavior: smooth;
}

p {
  letter-spacing: 0.5px;
}

span {
  letter-spacing: 0.5px;
}

h1 {
  letter-spacing: 0.5px;
}

.App {
  text-align: center;
  padding-top: 40px;
  background-color: var(--app-background);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'geomanistregular';
  src: url('./fonts/geomanist-regular-webfont.eot');
  src: url('./fonts/geomanist-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url('./fonts/geomanist-regular-webfont.woff2') format('woff2'),
    url('./fonts/geomanist-regular-webfont.woff') format('woff'),
    url('./fonts/geomanist-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

html,
body {
  font-family: geomanistregular;
}
