.notifications-container {
  position: absolute;
  max-height: 560px;
  overflow-y: scroll;
  max-width: 480px;
  right: 40px;
  top: 90px;
  box-shadow: 0px 8px 36px -12px rgba(76, 109, 141, 0.2);
  border-radius: 10px;
  font-family: var(--font);
  font-weight: bold;
  z-index: 3;
}

.list-item {
  font-family: var(--font);
  font-weight: bold;
  cursor: pointer;
  border-bottom: 1px solid rgb(233, 233, 233);
}

.notification-name {
  font-family: var(--font);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #464e5f;
}

.notification-message {
  font-family: var(--font);
  color: #727279;
  line-height: 17px;
  font-size: 14px;
  font-weight: 600;
  margin-top: -1px;
}

.see-more-center {
  font-family: var(--font);
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  padding-bottom: 10px;
  padding-top: 10px;
  color: cornflowerblue;
}

.notification-pic-radius {
  border-radius: 50%;
  margin: 0px;
}
