.custom-menu-list {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  max-height: 450px;
  background-color: #4083e0;
  z-index: 1000;
  border-radius: 4px;
}

.selected-service {
  text-decoration: none;
  color: white;
  font-size: 14px;

  &:hover {
    color: white;
    text-decoration: underline;
    column-gap: 2px;
  }
}
.selected-service:focus {
  background-color: #f0f0f0;
  outline: none;
}

.arrow-div {
  margin-left: 4px;
}
