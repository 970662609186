.account-dropdown {
  position: relative;
  display: inline-block;
  text-align: left;
  padding-left: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  height: 64px;
  border-radius: 10px;
  padding: 10px 80px 10px 20px;
  margin-right: 12px;
  background: #ffffff;
}

.account-dropdown-v1 {
  position: relative;
  display: inline-block;
  text-align: left;
  display: flex;
  align-items: center;
  height: 64px;
  border-radius: 10px;
  background: #ffffff;
}

.account-dropdown-content {
  font-family: var(--font);
  font-weight: bold;
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 198px;
  top: 65px;
  right: 0px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 3;
}
.img-vertical-align {
  vertical-align: top;
  margin-bottom: 8px;
}

.sign-out-element {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 16px;
}
/* Links inside the dropdown */
.account-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.header-image-dropdown {
  border-radius: 50%;
}

.align-user-info {
  padding-left: 10px;
  /* padding-bottom: 5px; */
  display: flex;
  flex-direction: column;
}

/* Change color of dropdown links on hover */
.account-dropdown-content a :hover {
  background-color: #f1f1f1;
}
.sign-out-element:hover {
  background-color: #f1f1f1;
}
.sign-out-element-1:hover {
  background-color: #f1f1f1;
}
.account-dropdown:hover .account-dropdown-content {
  display: block;
}
.doc-logo {
  display: none;
}

.profile-redirect {
  margin-bottom: 20px;
  margin-right: 20px;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  font-family: var(--font);
  cursor: pointer;
}

.profile-box {
  padding: 20px 24px;
  background: white;
  border-radius: 8px;
  color: #464e5f;
}

@media only screen and (max-width: 960px) {
  .account-dropdown {
    display: none;
  }
  .doc-logo {
    display: block;
    height: 100%;
  }
  .notification-bell {
    margin-right: 0;
  }
}
